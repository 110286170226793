import * as React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Menu from "./menu"
import { UTIL } from "../util/constants"
import Lottie from "react-lottie"
import animationData from "../util/data.json"

const Header = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <Head>
      <div>
        <h1>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <Lottie
              options={defaultOptions}
              width={"325px"}
              isClickToPauseDisabled={true}
              ariaRole={""}
            />
          </Link>
        </h1>
      </div>
      <MenuWrap>
        <Menu />
      </MenuWrap>
    </Head>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const Head = styled.header`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 6px;
  text-align: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  background-color: #fff;
  height: 80px;

  h1 {
    margin-bottom: 0;
  }

  svg {
    width: 325px !important;
    transition: ${UTIL.BaseTransitionSpeed};

    @media screen and (max-width: ${UTIL.BreakPointMobile}) {
      width: 262px !important;
    }
  }
`

const MenuWrap = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  right: 0;
`

export default Header

import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Link as Scroll } from "react-scroll"
import { UTIL } from "../util/constants"

const Footer = () => (
  <Foot>
    <Scroll to="top" smooth={true} duration={450}>
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 439.07 70">
          <path
            d="M93.7 27.36A28.28 28.28 0 0 0 52.48 3.14a34.85 34.85 0 0 1 9.86 17.17 8.56 8.56 0 0 1 3-.58A8.66 8.66 0 0 1 74 28.37v31.77a9.87 9.87 0 0 0 19.73 0V28.37c.02-.37 0-.68-.03-1.01z"
            fill="#00b4e5"
          />
          <path
            d="M56.69 27.36a28.34 28.34 0 0 0-56.64 0v1 31.78a9.87 9.87 0 0 0 19.73 0V28.37a8.64 8.64 0 1 1 17.28 0v7.93a9.87 9.87 0 0 0 19.73 0v-7.93l-.1-1.01zM46.88 70a9 9 0 1 1 6.37-2.64A9.09 9.09 0 0 1 46.88 70zm110.66-48.06a11.52 11.52 0 0 0-9.22 4.49 11.56 11.56 0 0 0-9.23-4.49 12.18 12.18 0 0 0-12.52 12.47v18.86a3.27 3.27 0 0 0 6.53 0v-19a6 6 0 0 1 11.92 0v19a3.32 3.32 0 0 0 6.64 0v-19a5.66 5.66 0 0 1 5.88-5.93 5.78 5.78 0 0 1 6 5.93v19a3.29 3.29 0 0 0 6.58 0V34.41a12.19 12.19 0 0 0-12.58-12.47zm49.99 0a3.29 3.29 0 0 0-3.29 3.29v28a3.29 3.29 0 1 0 6.58 0v-28a3.3 3.3 0 0 0-3.29-3.29zm61.02-.02a3.32 3.32 0 0 0-3.32 3.31v6.44c0 3.74-2.24 5.93-5.93 5.93s-5.93-2.19-5.93-5.93v-6.44a3.32 3.32 0 0 0-6.64 0v6.29a12.61 12.61 0 0 0 3.49 9 11.88 11.88 0 0 0 5.78 3.17v9.58a3.32 3.32 0 0 0 6.63 0v-9.58a11.88 11.88 0 0 0 5.74-3.14 12.61 12.61 0 0 0 3.49-9v-5.82a3.85 3.85 0 0 0 .05-.5 3.32 3.32 0 0 0-3.36-3.31zm60.81.02a3.29 3.29 0 0 0-3.29 3.29v18.91c0 3.74-2.25 6-6 6s-5.94-2.24-5.94-6V25.23a3.29 3.29 0 1 0-6.58 0V44a12.7 12.7 0 0 0 3.49 9 13.45 13.45 0 0 0 18.1 0 12.7 12.7 0 0 0 3.49-9V25.23h0a3.29 3.29 0 0 0-3.27-3.29zm106.42 0a3.29 3.29 0 0 0-3.29 3.3v28a3.29 3.29 0 0 0 6.58 0v-28a3.29 3.29 0 0 0-3.29-3.3zm-52.4 15.22l9.62-9.59a3.292 3.292 0 0 0-4.65-4.66l-12.97 12.94V25.24a3.29 3.29 0 1 0-6.58 0v28a3.29 3.29 0 0 0 6.58 0v-8.09l3.28-3.27L388 55.16a3.292 3.292 0 0 0 5.39-3.78z"
            fill="#28367b"
          />
        </svg>
      </Link>
    </Scroll>
  </Foot>
)

const Foot = styled.footer`
  text-align: center;
  padding: 16px;

  svg {
    width: 250px;
    transition: ${UTIL.BaseTransitionSpeed};

    @media screen and (max-width: ${UTIL.BreakPointMobile}) {
      width: 220px;
    }
  }
`

export default Footer

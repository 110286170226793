import * as React from "react"
import styled from "styled-components"
import { UTIL, COLORS, FONT } from "../util/constants"
import { Link as Scroll } from "react-scroll"

const Menu = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <>
      <DrawerText
        onClick={() => {
          // toggle
          setIsOpen(prevState => !prevState)
        }}
        isOpen={isOpen}
      >
        {isOpen ? "close" : "menu"}
      </DrawerText>
      <div className="drawer">
        <span
          onClick={() => {
            // toggle
            setIsOpen(prevState => !prevState)
          }}
          className={isOpen ? "open drawer-open" : "drawer-open"}
        >
          <span></span>
          <span></span>
        </span>
        <DrawerContent
          className={isOpen ? "open drawer-content" : "drawer-content"}
        >
          <SvgWrap>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210 208.76">
              <title>みゆき塗料商会ロゴ</title>
              <path
                d="M209.88 61.29A63.36 63.36 0 0 0 117.56 7a78.21 78.21 0 0 1 22.08 38.5 19.09 19.09 0 0 1 6.81-1.3 19.37 19.37 0 0 1 19.35 19.35v71.15a22.1 22.1 0 1 0 44.2 0V63.55a21 21 0 0 0-.12-2.26z"
                fill="#00b4e5"
              />
              <path
                d="M127 61.29a63.47 63.47 0 0 0-126.86 0A21 21 0 0 0 0 63.55v71.15a22.1 22.1 0 0 0 44.2 0V63.55a19.35 19.35 0 0 1 38.7 0V81.3a22.1 22.1 0 1 0 44.2 0V63.55a21 21 0 0 0-.1-2.26zm-22 95.51a20.23 20.23 0 1 1 14.27-5.92A20.33 20.33 0 0 1 105 156.8zm-84.19 28.69a7.75 7.75 0 0 0-6.2 3 7.73 7.73 0 0 0-6.2-3A8.18 8.18 0 0 0 0 193.87v12.67a2.2 2.2 0 0 0 4.39 0V193.8a3.87 3.87 0 0 1 4-4 3.85 3.85 0 0 1 4 4v12.74a2.23 2.23 0 0 0 4.46 0V193.8a3.81 3.81 0 0 1 3.95-4 3.89 3.89 0 0 1 4 4v12.74a2.21 2.21 0 0 0 4.42 0v-12.67a8.19 8.19 0 0 0-8.41-8.38zm33.59 0a2.21 2.21 0 0 0-2.21 2.21v18.84a2.21 2.21 0 0 0 4.42 0V187.7a2.21 2.21 0 0 0-2.21-2.21zm41.01-.02a2.23 2.23 0 0 0-2.23 2.23v4.3a4 4 0 1 1-8 0v-4.3a2.23 2.23 0 1 0-4.46 0v4.22A8.45 8.45 0 0 0 83.1 198a7.82 7.82 0 0 0 3.9 2.1v6.44a2.23 2.23 0 0 0 4.46 0v-6.44a7.9 7.9 0 0 0 3.8-2.1 8.45 8.45 0 0 0 2.35-6.07V188v-.33a2.23 2.23 0 0 0-2.2-2.2zm40.87.02a2.21 2.21 0 0 0-2.22 2.21v12.7a4 4 0 1 1-8 0v-12.7a2.21 2.21 0 0 0-4.42 0v12.64a8.43 8.43 0 1 0 16.86 0V187.7h0a2.21 2.21 0 0 0-2.22-2.21zm71.51 0a2.21 2.21 0 0 0-2.21 2.21v18.84a2.21 2.21 0 0 0 4.42 0V187.7a2.21 2.21 0 0 0-2.21-2.21zm-35.21 10.22l6.44-6.44a2.213 2.213 0 0 0-3.13-3.13l-8.69 8.69v-7.13a2.21 2.21 0 0 0-4.42 0v18.84a2.21 2.21 0 0 0 4.42 0v-5.45l2.2-2.2 6.25 8.92a2.211 2.211 0 1 0 3.62-2.54z"
                fill="#fff"
              />
            </svg>
          </SvgWrap>
          <DrawerList>
            <li className="drawer-item">
              <Scroll
                to="content"
                smooth={true}
                offset={-90}
                duration={750}
                onClick={() => {
                  // toggle
                  setIsOpen(prevState => !prevState)
                }}
              >
                業務内容
              </Scroll>
            </li>
            <li className="drawer-item">
              <Scroll
                to="maker"
                smooth={true}
                offset={-90}
                duration={750}
                onClick={() => {
                  // toggle
                  setIsOpen(prevState => !prevState)
                }}
              >
                取扱いメーカー及び企業
              </Scroll>
            </li>
            <li className="drawer-item">
              <Scroll
                to="service"
                smooth={true}
                offset={-90}
                duration={750}
                onClick={() => {
                  // toggle
                  setIsOpen(prevState => !prevState)
                }}
              >
                サービス内容のご紹介
              </Scroll>
            </li>
            <li className="drawer-item">
              <Scroll
                to="calendar"
                smooth={true}
                offset={-90}
                duration={750}
                onClick={() => {
                  // toggle
                  setIsOpen(prevState => !prevState)
                }}
              >
                研修カレンダー
              </Scroll>
            </li>
            <li className="drawer-item">
              <Scroll
                to="archive"
                smooth={true}
                offset={-90}
                duration={750}
                onClick={() => {
                  // toggle
                  setIsOpen(prevState => !prevState)
                }}
              >
                研修アーカイブ
              </Scroll>
            </li>
            <li className="drawer-item">
              <Scroll
                to="corporate"
                smooth={true}
                offset={-90}
                duration={750}
                onClick={() => {
                  // toggle
                  setIsOpen(prevState => !prevState)
                }}
              >
                企業情報
              </Scroll>
            </li>
            <li className="drawer-item">
              <Scroll
                to="contact"
                smooth={true}
                offset={-90}
                duration={750}
                onClick={() => {
                  // toggle
                  setIsOpen(prevState => !prevState)
                }}
              >
                お問い合わせ
              </Scroll>
            </li>
            <li className="drawer-item">
              <Scroll
                to="recruit"
                smooth={true}
                offset={-90}
                duration={750}
                onClick={() => {
                  // toggle
                  setIsOpen(prevState => !prevState)
                }}
              >
                採用情報
              </Scroll>
            </li>
          </DrawerList>
        </DrawerContent>
      </div>
    </>
  )
}

const DrawerText = styled.span`
  font-size: ${FONT.Size15};
  font-family: "Avenir Next", "Segoe UI", Helvetica, sans-serif;
  cursor: pointer;
  z-index: 3;
  color: ${props => (props.isOpen ? "#fff" : COLORS.PrimaryBlue)};
  font-weight: 500;
  letter-spacing: 1px;

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    display: none;
  }
`

const DrawerContent = styled.nav`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 100%; /* メニューを画面の外に */
  z-index: 2;
  transition: ${UTIL.BaseTransitionSpeed};
  background: rgb(0, 130, 230);
  background: linear-gradient(
    0deg,
    rgba(0, 130, 230, 0.85) 0%,
    rgba(0, 130, 230, 0.85) 100%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 90px;

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    padding: 40px 60px;
  }
`

const SvgWrap = styled.div`
  margin-top: 68px;

  @media screen and (max-width: ${UTIL.BreakPointMobile}) {
    display: none;
  }
`

const DrawerList = styled.ul`
  text-align: left;
  margin-bottom: 0;

  li {
    font-size: ${FONT.Size15};
    cursor: pointer;

    & + li {
      margin-top: 2rem;
    }

    a {
      color: #fff;
    }
  }
`

export default Menu

export const COLORS = {
  Primary: '#1473E6',
  PrimaryBlue: '#0082E6',
  SecondaryBlue: '#28367b',
  PrimaryYellow: '#f8ac39',
  PrimaryRed: '#d83530',
  PrimaryGreen: '#33bb20',
  Text: '#383838',
  TextLight: '#989AA1',
  TextPrimaryGray: '#989aa1',
  Border: '#EDEDED',
  Success: '#0F8300',
  Danger: '#d83530',
  Placeholder: '#B9B9B9',
  BgGray: '#f7f7f9',
  BgPaleGray: '#fcfcfc',
  BgPaleWhite: '#fffbf4',
  BgPaleOrange: '#ffddaa',
  BgModal: 'rgba(69, 41, 0, 0.6)',
  BorderGray: '#ededed',
  BgHeader: '#f5f3ef',
  BgCollapseWhite: 'linear-gradient(180deg,rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)',
  BgCollapsePaleWhite: 'linear-gradient(180deg, rgba(255,251,244,0) 0%, rgba(255,251,244,1) 100%)',
  BgCollapsePaleGray: 'linear-gradient(180deg, rgba(252,252,252,0) 0%, rgba(252,252,252,1) 100%)',
  BgCollapseGray: 'linear-gradient(180deg, rgba(247,247,249,0) 0%, rgba(247,247,249,1) 100%)',
  BgFadeWhite: 'rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%',
  BgFadePaleWhite: 'rgba(255,251,244,0) 0%, rgba(255,251,244,1) 100%',
  BgFadePaleGray: 'rgba(252,252,252,0) 0%, rgba(252,252,252,1) 100%',
  BgFadeGray: 'rgba(247,247,249,0) 0%, rgba(247,247,249,1) 100%',
  TextPrimaryBlack: '#000',
  TextPrimaryPaleBlue: '#28367b',
  TextPaleWhite: '#f5f3ef',
  YouTubeRed: '#de0016',
}

export const FONT = {
  Size10: '.83rem',
  Size11: '.91rem',
  Size12: '1rem',
  Size13: '1.08rem',
  Size14: '1.16rem',
  Size15: '1.25rem',
  Size16: '1.33rem',
  Size17: '1.41rem',
  Size18: '1.5rem',
  Size19: '1.58rem',
  Size20: '1.66rem',
  Size21: '1.75rem',
  Size22: '1.83rem',
  Size23: '1.91rem',
  Size24: '2rem',
  Size25: '2.08rem',
  Size26: '2.16rem',
  Size27: '2.25rem',
  Size28: '2.33rem',
}

export const UTIL = {
  BaseInnerWidth: '1100px',
  BaseContentWrapWidth: '88%',
  BaseRadius: '8px',
  BaseShadow: '0 0 5px rgba(0,0,0,.09)',
  BaseShadowHover: '0 5px 16px rgba(0,0,0,.09)',
  BaseTransitionSpeed: '.55s',
  FadeInSpeed: '2s',
  BaseMobileGutter: '12px',
  BreakPointDesktopLarge: '1900px',
  BreakPointDesktopMiddle: '1600px',
  BreakPointDesktopSmallPlusOne: '1201px',
  BreakPointDesktopSmall: '1200px',
  BreakPointTabletLarge: '1087px',
  BreakPointTabletPlusOne: '921px',
  BreakPointTablet: '920px',
  BreakPointMobilePlusOne: '668px',
  BreakPointMobile: '667px',
  mobileMessageHeight: 78,
}